import { defaultSettings } from '../config/settings'
import { ApiError } from '../helpers/ProxyApiFetch/types'
import { ComplexSettings, Settings } from '../model'

export enum SettingsActionTypes {
  startFetchSettings = 'START_FETCH_SETTINGS',
  successFetchSettings = 'SUCCESS_FETCH_SETTINGS',
  failFetchSettings = 'FAIL_FETCH_SETTINGS',
  setSettings = 'SET_SETTINGS',

  startUpdateSettings = 'UPDATE_SETTINGS',
  successUpdateSettings = 'SUCCESS_UPDATE_SETTINGS',
  failUpdateSettings = 'FAIL_UPDATE_SETTINGS',

  initSettings = 'INIT_SETTINGS',
  deleteSettings = 'DELETE_SETTINGS',
  resetToDefaults = 'SETTINGS_RESET_TO_DEFAULTS', // for test reset
}

export interface ResetToDefaultsAction {
  type: SettingsActionTypes.resetToDefaults
}

export interface SuccessUpdateSettingsAction {
  type: SettingsActionTypes.successUpdateSettings
  payload?: ComplexSettings
}

export interface StartUpdateSettingsAction {
  type: SettingsActionTypes.startUpdateSettings
}

export interface DeleteSettingsAction {
  type: SettingsActionTypes.deleteSettings
}

export interface SetSettingsAction {
  type: SettingsActionTypes.setSettings
  payload: Settings
}

export interface StartFetchSettingsAction {
  type: SettingsActionTypes.startFetchSettings
}

export type SuccessFetchSettingsAction = {
  type: SettingsActionTypes.successFetchSettings
  payload: { settings: Settings }
}

export interface FailFetchSettingsAction {
  type: SettingsActionTypes.failFetchSettings
  payload: ApiError | undefined
}

// update

export interface FailUpdateSettingsAction {
  type: SettingsActionTypes.failUpdateSettings
  payload: ApiError | null
}

export type SettingsAction =
  | SetSettingsAction
  | StartFetchSettingsAction
  | SuccessFetchSettingsAction
  | FailFetchSettingsAction
  | FailUpdateSettingsAction
  | DeleteSettingsAction
  | StartUpdateSettingsAction
  | SuccessUpdateSettingsAction
  | ResetToDefaultsAction

export type SettingsState = {
  settingsData: Settings
  isLoaded: boolean
  isLoading: boolean
  error: null | ApiError
}
export const defaultSettingsState: SettingsState = {
  settingsData: {
    ...defaultSettings,
  },
  isLoaded: false,
  isLoading: false,
  error: null,
}

const settings = (
  state: SettingsState = defaultSettingsState,
  action: SettingsAction
): SettingsState => {
  switch (action.type) {
    case SettingsActionTypes.startFetchSettings:
      return { ...state, isLoading: true }
    case SettingsActionTypes.setSettings:
      return { ...state, settingsData: { ...action.payload } }
    case SettingsActionTypes.successFetchSettings:
      return {
        ...state,
        settingsData: { ...state.settingsData, ...action.payload.settings },
        isLoading: false,
        isLoaded: true,
      }
    case SettingsActionTypes.failFetchSettings:
      return { ...state, isLoading: false, isLoaded: false }
    case SettingsActionTypes.resetToDefaults:
      return { ...defaultSettingsState }
    case SettingsActionTypes.startUpdateSettings:
      return { ...state, isLoading: true }
    case SettingsActionTypes.successUpdateSettings:
      return { ...state, isLoading: false, isLoaded: true }
    case SettingsActionTypes.failUpdateSettings:
      return { ...state, isLoading: false, error: action.payload }
    default:
      return state
  }
}

export default settings

import { model } from '@telekomconsalting/dex-guru-model'
import { TokenHoldersCategoryName } from '@telekomconsalting/dex-guru-model/lib/model'

import { ActiveHolderChartData } from '../components/TokenProfile/tokenProfileReducer'
import { TraderBalanceRowData } from '../model'
export const SET_TRADER_PROFILE_TOTALS = 'SET_TRADER_PROFILE_TOTALS'
export const SET_TRADER_PROFILE_WALLET = 'SET_TRADER_PROFILE_WALLET'
export const SET_TRADER_PROFILE_CHART = 'SET_TRADER_PROFILE_CHART'
export const SET_TRADER_PROFILE_CHART_ID = 'SET_TRADER_PROFILE_CHART_ID'
export const SET_TRADER_PROFILE_TXN = 'SET_TRADER_PROFILE_TXN'
export const SET_TRADER_PROFILE_PREV_PATHNAME = 'SET_TRADER_PROFILE_PREV_PATHNAME'
export const SET_TRADER_PROFILE_LOADING = 'SET_TRADER_PROFILE_LOADING'
export const SET_TRADER_PROFILE_TAB = 'SET_TRADER_PROFILE_TAB'
export const DROP_TRADER_PROFILE = 'DROP_TRADER_PROFILE'

export type TraderProfileAction =
  | SetTraderProfileActionTotals
  | SetTraderProfileActionWallet
  | SetTraderProfileActionChart
  | SetTraderProfileActionChartId
  | SetTraderProfileActionTxn
  | SetTraderProfileActionPrevPathname
  | SetTraderProfileLoading
  | SetTraderProfileTab
  | DropTraderProfile

export interface SetTraderProfileActionTotals {
  type: 'SET_TRADER_PROFILE_TOTALS'
  payload: TraderProfileState
}
export interface SetTraderProfileActionWallet {
  type: 'SET_TRADER_PROFILE_WALLET'
  payload: TraderProfileState
}
export interface SetTraderProfileActionChart {
  type: 'SET_TRADER_PROFILE_CHART'
  payload: TraderProfileState
}
export interface SetTraderProfileActionChartId {
  type: 'SET_TRADER_PROFILE_CHART_ID'
  payload: TraderProfileState
}

export interface SetTraderProfileActionTxn {
  type: 'SET_TRADER_PROFILE_TXN'
  payload: TraderProfileState
}

export interface SetTraderProfileActionPrevPathname {
  type: 'SET_TRADER_PROFILE_PREV_PATHNAME'
  payload: TraderProfileState
}
export interface SetTraderProfileLoading {
  type: 'SET_TRADER_PROFILE_LOADING'
  payload: Partial<TraderProfileState>
}
export interface SetTraderProfileTab {
  type: 'SET_TRADER_PROFILE_TAB'
  payload: Partial<TraderProfileState>
}
export interface DropTraderProfile {
  type: 'DROP_TRADER_PROFILE'
  payload: Partial<TraderProfileState>
}

export enum TraderProfileTabs {
  token = 'token',
  lp = 'lp',
}

export interface TraderProfileState {
  activeTxn?: string
  assetsValue?: number
  assetsValueChange24H?: number
  balances?: TraderBalanceRowData[]
  chart?: ActiveHolderChartData
  shownChartId?: string
  tradingVolume?: number
  tradingVolumeChange30D?: number
  walletAddress?: string
  pathname?: string
  walletCategory?: model.Category
  reqAllowed?: boolean
  isLoading?: boolean
  tab?: TraderProfileTabs
  detail?: string
  holderType?: TokenHoldersCategoryName
  label?: string
}

export const defaultTraderProfileState = {
  chart: { timestamp: [], value: [], price: [] },
  reqAllowed: true,
  isLoading: false,
  tab: TraderProfileTabs.token,
}

const traderProfileReducer = (
  state: TraderProfileState = defaultTraderProfileState,
  action: TraderProfileAction
): TraderProfileState => {
  switch (action.type) {
    case SET_TRADER_PROFILE_TOTALS: {
      const {
        walletAddress,
        walletCategory,
        assetsValue,
        assetsValueChange24H,
        tradingVolume,
        tradingVolumeChange30D,
        balances,
        holderType,
        label,
      }: TraderProfileState = action.payload
      return {
        ...state,
        walletAddress,
        walletCategory,
        assetsValue,
        assetsValueChange24H,
        tradingVolume,
        tradingVolumeChange30D,
        balances,
        holderType,
        label,
      }
    }
    case SET_TRADER_PROFILE_WALLET: {
      const { walletAddress }: TraderProfileState = action.payload
      return {
        ...state,
        walletAddress,
      }
    }
    case SET_TRADER_PROFILE_CHART_ID: {
      const { shownChartId }: TraderProfileState = action.payload
      return {
        ...state,
        shownChartId,
      }
    }
    case SET_TRADER_PROFILE_CHART: {
      const { chart }: TraderProfileState = action.payload
      return {
        ...state,
        chart,
      }
    }
    case SET_TRADER_PROFILE_TXN: {
      const { activeTxn }: TraderProfileState = action.payload
      return {
        ...state,
        activeTxn,
      }
    }
    case SET_TRADER_PROFILE_PREV_PATHNAME: {
      const { pathname }: TraderProfileState = action.payload
      return {
        ...state,
        pathname,
      }
    }
    case SET_TRADER_PROFILE_LOADING: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case SET_TRADER_PROFILE_TAB: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case DROP_TRADER_PROFILE: {
      return {
        ...defaultTraderProfileState,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export default traderProfileReducer

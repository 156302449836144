import { TopCryptosToken, TopCryptosTotals } from '../model'

export const SET_TOKENS_TOP_CRYPTOS = 'SET_TOKENS_TOP_CRYPTOS'
export const SET_TOTALS_TOP_CRYPTOS = 'SET_TOTALS_TOP_CRYPTOS'
export const SET_LOADING_TOKENS_TOP_CRYPTOS = 'SET_LOADING_TOKENS_TOP_CRYPTOS'
export const SET_LOADING_TOTALS_TOP_CRYPTOS = 'SET_LOADING_TOTALS_TOP_CRYPTOS'

export interface SetTokensTopCryptosAction {
  type: 'SET_TOKENS_TOP_CRYPTOS'
  payload: { tokens: TopCryptosToken[] }
}

export interface SetLoadingTokensTopCryptosAction {
  type: 'SET_LOADING_TOKENS_TOP_CRYPTOS'
  payload: { tokensLoading: boolean }
}
export interface SetTotalsTopCryptosAction {
  type: 'SET_TOTALS_TOP_CRYPTOS'
  payload: { totals: TopCryptosTotals }
}
export interface SetLoadingTotalsTopCryptosAction {
  type: 'SET_LOADING_TOTALS_TOP_CRYPTOS'
  payload: { totalsLoading: boolean }
}

export type TopCryptosAction =
  | SetTokensTopCryptosAction
  | SetTotalsTopCryptosAction
  | SetLoadingTotalsTopCryptosAction
  | SetLoadingTokensTopCryptosAction

const topCryptos = (
  state: TopCryptosState = defaultTopCryptosState,
  action: TopCryptosAction
): TopCryptosState => {
  switch (action.type) {
    case SET_TOTALS_TOP_CRYPTOS: {
      const { totals } = action.payload
      return { ...state, totals }
    }

    case SET_LOADING_TOTALS_TOP_CRYPTOS: {
      const { totalsLoading } = action.payload
      return { ...state, totalsLoading }
    }

    case SET_TOKENS_TOP_CRYPTOS: {
      const { tokens } = action.payload
      return { ...state, tokens }
    }

    case SET_LOADING_TOKENS_TOP_CRYPTOS: {
      const { tokensLoading } = action.payload
      return { ...state, tokensLoading }
    }

    default:
      return state
  }
}

export const defaultTopCryptosState = {
  totals: {},
  totalsLoading: false,
  tokens: [],
  tokensLoading: false,
}

export interface TopCryptosState {
  totals: TopCryptosTotals
  totalsLoading: boolean
  tokens: TopCryptosToken[]
  tokensLoading: boolean
}

export default topCryptos

import { TransactionModel } from '../model'

export const SET_TRADING_HISTORY = 'SET_TRADING_HISTORY'
export const RESET_TRADING_HISTORY = 'RESET_TRADING_HISTORY'

export const SET_PROFILE_TRADING_HISTORY = 'SET_PROFILE_TRADING_HISTORY'
export const RESET_PROFILE_TRADING_HISTORY = 'RESET_PROFILE_TRADING_HISTORY'

type TradingHistoryAction =
  | SetTradingHistory
  | ResetTradingHistory
  | SetProfileTradingHistory
  | ResetProfileTradingHistory

export interface SetTradingHistory {
  type: 'SET_TRADING_HISTORY'
  currentTradingHistory?: TransactionModel[]
  origin: TradeOrigin
}

export interface ResetTradingHistory {
  type: 'RESET_TRADING_HISTORY'
}

export interface SetProfileTradingHistory {
  type: 'SET_PROFILE_TRADING_HISTORY'
  currentProfileTradingHistory?: TransactionModel[]
}

export interface ResetProfileTradingHistory {
  type: 'RESET_PROFILE_TRADING_HISTORY'
}

const tradingHistory = (
  state = defaultTradingHistoryState,
  action: TradingHistoryAction
): TradingHistoryState => {
  switch (action.type) {
    case SET_TRADING_HISTORY:
      return {
        ...state,
        currentTradingHistory: action.currentTradingHistory,
        previousTradingHistory: state.currentTradingHistory,
        currentTradingHistoryOrigin: action.origin,
        previousTradingHistoryOrigin: state.currentTradingHistoryOrigin,
      }
    case RESET_TRADING_HISTORY:
      return {
        ...state,
        currentTradingHistory: undefined,
        previousTradingHistory: undefined,
      }
    case SET_PROFILE_TRADING_HISTORY:
      return {
        ...state,
        currentProfileTradingHistory: action.currentProfileTradingHistory,
        previousProfileTradingHistory: state.currentProfileTradingHistory,
      }
    case RESET_PROFILE_TRADING_HISTORY:
      return {
        ...state,
        currentProfileTradingHistory: undefined,
        previousProfileTradingHistory: undefined,
      }
    default:
      return state
  }
}

export const defaultTradingHistoryState: TradingHistoryState = {
  currentTradingHistory: undefined,
  previousTradingHistory: undefined,
  currentProfileTradingHistory: undefined,
  previousProfileTradingHistory: undefined,
}

export type TradeOrigin = 'all' | 'my_orders'

export type TradingHistoryState = {
  currentTradingHistory?: TransactionModel[]
  currentTradingHistoryOrigin?: TradeOrigin
  previousTradingHistory?: TransactionModel[]
  previousTradingHistoryOrigin?: TradeOrigin
  currentProfileTradingHistory?: TransactionModel[]
  previousProfileTradingHistory?: TransactionModel[]
}

export default tradingHistory

import Web3 from 'web3'

import {
  Address,
  TokenBalance,
  TokenId,
  TokenV3,
  TokenWithApprovalContext,
  UpdateTokenPrice,
} from '../model'
import { addressMatch, deleteDuplicatedItem } from '../utils'

export const SET_QUOTE_TOKEN = 'SET_QUOTE_TOKEN'
export const SET_CURRENT_TOKEN = 'SET_CURRENT_TOKEN'
export const UPDATE_CURRENT_TOKEN = 'UPDATE_CURRENT_TOKEN'
export const UPDATE_QUOTE_TOKEN = 'UPDATE_QUOTE_TOKEN'
export const START_FETCH_TOKENS = 'START_FETCH_TOKENS'
export const UPDATE_TOKENS = 'UPDATE_TOKENS'
export const FAIL_FETCH_TOKENS = 'FAIL_FETCH_TOKENS'
export const UPDATE_TOKEN_PRICES = 'UPDATE_TOKEN_PRICES'
export const STORE_TOKENS = 'STORE_TOKENS'
export const INIT_TOKENS = 'INIT_TOKENS'
export const SET_TOKEN_LOADING = 'SET_TOKEN_LOADING'
export const ADD_FAVORITE_TOKEN = 'ADD_FAVORITE_TOKEN'
export const REMOVE_FAVORITE_TOKEN = 'REMOVE_FAVORITE_TOKEN'
export const MERGE_TOKENS = 'MERGE_TOKENS'
export const SET_FAVORITES = 'SET_FAVORITES'
export const UPDATE_WALLET = 'UPDATE_WALLET'

export const START_ADD_FAVORITE_TOKEN = 'START_ADD_FAVORITE_TOKEN'
export const SUCCESS_ADD_FAVORITE_TOKEN = 'SUCCESS_ADD_FAVORITE_TOKEN'
export const FAIL_ADD_FAVORITE_TOKEN = 'FAIL_ADD_FAVORITE_TOKEN'

export const START_REMOVE_FAVORITE_TOKEN = 'START_REMOVE_FAVORITE_TOKEN'
export const SUCCESS_REMOVE_FAVORITE_TOKEN = 'SUCCESS_REMOVE_FAVORITE_TOKEN'
export const FAIL_REMOVE_FAVORITE_TOKEN = 'FAIL_REMOVE_FAVORITE_TOKEN'
export const ADD_FAVORITE_TO_SYNC_QUEUE = 'ADD_FAVORITE_TO_SYNC_QUEUE'
export const REMOVE_FAVORITE_TO_SYNC_QUEUE = 'REMOVE_FAVORITE_TO_SYNC_QUEUE'
export const NOTHING_TO_SYNC_FAVORITES = 'NOTHING_TO_SYNC_FAVORITES'
export const START_SYNC_FAVORITES = 'START_SYNC_FAVORITES'
export const SUCCESS_SYNC_FAVORITES = 'SUCCESS_SYNC_FAVORITES'
export const FAIL_SYNC_FAVORITES = 'FAIL_SYNC_FAVORITES'

export type TokenAction =
  | SetQuoteTokenAction
  | UpdateQuoteTokenAction
  | SetCurrentTokenAction
  | UpdateCurrentTokenAction
  | StoreTokensAction
  | InitTokensAction
  | UpdateTokensAction
  | LoadingAction
  | AddFavoriteAction
  | RemoveFavoriteAction
  | MergeTokensAction
  | SetFavoritesAction
  | UpdateTokenPricesAction
  | UpdateWallet
  | StartAddFavoriteTokenAction
  | SuccessAddFavoriteTokenAction
  | FailAddFavoriteTokenAction
  | StartRemoveFavoriteTokenAction
  | SuccessRemoveFavoriteTokenAction
  | FailRemoveFavoriteTokenAction
  | AddFavoriteToSyncQueueAction
  | RemoveFavoriteToSyncQueueAction
  | NothingToSyncFavoritesAction
  | StartSyncFavoritesAction
  | SuccessSyncFavoritesAction
  | FailSyncFavoritesAction
  | StartFetchTokens
  | FailFetchTokens

export interface StartFetchTokens {
  type: 'START_FETCH_TOKENS'
}

export interface FailFetchTokens {
  type: 'FAIL_FETCH_TOKENS'
  payload: string
}

export interface SuccessSyncFavoritesAction {
  type: 'SUCCESS_SYNC_FAVORITES'
  payload?: string[]
}

export interface FailSyncFavoritesAction {
  type: 'FAIL_SYNC_FAVORITES'
  payload: Array<SyncFavoriteRecord>
}

export interface StartSyncFavoritesAction {
  type: 'START_SYNC_FAVORITES'
}

export interface NothingToSyncFavoritesAction {
  type: 'NOTHING_TO_SYNC_FAVORITES'
}

export interface AddFavoriteToSyncQueueAction {
  type: 'ADD_FAVORITE_TO_SYNC_QUEUE'
  payload: string
}

export interface RemoveFavoriteToSyncQueueAction {
  type: 'REMOVE_FAVORITE_TO_SYNC_QUEUE'
  payload: string
}

export interface StartAddFavoriteTokenAction {
  type: 'START_ADD_FAVORITE_TOKEN'
  payload: Array<string>
}

export interface SuccessAddFavoriteTokenAction {
  type: 'SUCCESS_ADD_FAVORITE_TOKEN'
  payload?: TokenId
}

export interface FailAddFavoriteTokenAction {
  type: 'FAIL_ADD_FAVORITE_TOKEN'
  payload?: string
}

export interface StartRemoveFavoriteTokenAction {
  type: 'START_REMOVE_FAVORITE_TOKEN'
  payload: string
}

export interface SuccessRemoveFavoriteTokenAction {
  type: 'SUCCESS_REMOVE_FAVORITE_TOKEN'
}

export interface FailRemoveFavoriteTokenAction {
  type: 'FAIL_REMOVE_FAVORITE_TOKEN'
  payload: string // tokenId
}

export interface SetQuoteTokenAction {
  type: 'SET_QUOTE_TOKEN'
  quoteToken: TokenWithApprovalContext
}

export interface UpdateQuoteTokenAction {
  type: 'UPDATE_QUOTE_TOKEN'
  quoteToken: Partial<TokenWithApprovalContext>
}

export interface SetCurrentTokenAction {
  type: 'SET_CURRENT_TOKEN'
  currentToken: TokenWithApprovalContext
}

export interface UpdateCurrentTokenAction {
  type: 'UPDATE_CURRENT_TOKEN'
  currentToken: Partial<TokenWithApprovalContext>
}

export interface StoreTokensAction {
  type: 'STORE_TOKENS'
  currentToken: TokenWithApprovalContext
  quoteToken?: TokenWithApprovalContext
  account?: Address | null
}

export interface InitTokensAction {
  type: 'INIT_TOKENS'
  currentToken?: TokenWithApprovalContext
  quoteToken?: TokenWithApprovalContext
  account?: Address
  library?: Web3
}
export interface UpdateTokensAction {
  type: 'UPDATE_TOKENS'
  tokens: TokenV3[]
}

export interface UpdateTokenPricesAction {
  type: 'UPDATE_TOKEN_PRICES'
  tokenPrices: UpdateTokenPrice[]
}

export interface LoadingAction {
  type: 'SET_TOKEN_LOADING'
  status: boolean
}

export interface AddFavoriteAction {
  type: 'ADD_FAVORITE_TOKEN'
  tokenId: string
}

export interface RemoveFavoriteAction {
  type: 'REMOVE_FAVORITE_TOKEN'
  tokenId: string
}

export interface MergeTokensAction {
  type: 'MERGE_TOKENS'
  tokens: TokenV3[]
}

export interface SetFavoritesAction {
  type: 'SET_FAVORITES'
  tokenIds: string[]
}

export interface UpdateWallet {
  type: 'UPDATE_WALLET'
  tokenBalances: TokenBalance[]
}

const mergeToken = (
  token: TokenV3 | undefined,
  tokens: TokenV3[]
): TokenWithApprovalContext | undefined => {
  if (!token) {
    return token
  }

  const foundToken = tokens.find((t) => t.id === token.id)

  if (foundToken) {
    return { ...token, ...foundToken }
  } else {
    return token
  }
}

const mergeTokens = (stateTokens: TokenV3[] = [], tokens: (TokenV3 | undefined)[]): TokenV3[] => {
  if (!stateTokens.length) {
    return tokens.filter((t) => !!t) as TokenV3[]
  }

  const mergedTokens = stateTokens
  tokens.forEach((token) => {
    if (token) {
      const index = mergedTokens.findIndex((t) => t?.id === token.id)
      if (index > -1) {
        mergedTokens.splice(index, 1, token)
      } else {
        mergedTokens.push(token)
      }
    }
  })

  return mergedTokens.filter((t) => !!t)
}

export const mergeState = (
  oldState: TokensState,
  update: Partial<TokensState>,
  tokenPrices?: UpdateTokenPrice[]
): TokensState => {
  let newState: TokensState = { ...oldState }

  if (update.quoteToken) {
    newState.tokens = mergeTokens(newState.tokens, [{ ...update.quoteToken }])
    newState.quoteToken = { ...update.quoteToken }
  }

  if (update.currentToken) {
    newState.tokens = mergeTokens(newState.tokens, [{ ...update.currentToken }])
    newState.currentToken = { ...update.currentToken }
  }

  if (update.tokens?.length) {
    const newQuoteToken = mergeToken(newState.quoteToken, update.tokens)
    const newCurrentToken = mergeToken(newState.currentToken, update.tokens)

    if (newCurrentToken) {
      newState.currentToken = newCurrentToken
    }

    if (newQuoteToken) {
      newState.quoteToken = newQuoteToken
    }

    newState.tokens = mergeTokens(newState.tokens, update.tokens)
  }

  if (update.favorites) {
    newState.favorites = deleteDuplicatedItem(newState.favorites.concat(update.favorites))
  }

  if (tokenPrices && tokenPrices.length) {
    newState = updatePrices(newState, tokenPrices)
  }

  return { ...newState, tokenLoading: false }
}

const updatePrices = (state: TokensState, prices: UpdateTokenPrice[]): TokensState => {
  const newState = { ...state }

  newState.currentToken = updatePrice(state.currentToken, prices)
  newState.quoteToken = updatePrice(state.quoteToken, prices)

  newState.tokens = newState.tokens.map((t) => updatePrice(t, prices))

  return newState
}

const updatePrice = <T extends TokenV3 | undefined>(token: T, prices: UpdateTokenPrice[]): T => {
  const newPrice = prices.find((p) => addressMatch({ address: token?.address }, p))

  if (!newPrice || !token) {
    return token
  }

  const priceUSD = newPrice.currency === 'USD' ? newPrice.token_price : token.priceUSD
  const priceETH = newPrice.currency === 'USD' ? token.priceETH : newPrice.token_price

  return {
    ...token,
    priceUSD,
    priceETH,
  }
}

const tokensReducer = (
  state: TokensState = defaultTokensState,
  action: TokenAction
): TokensState => {
  switch (action.type) {
    case SET_QUOTE_TOKEN:
      return mergeState(state, { quoteToken: action.quoteToken })

    case UPDATE_QUOTE_TOKEN: {
      // partial update is going to be applied to the empty quote token
      if (!state.quoteToken?.id && !action.quoteToken?.id) {
        return state
      }
      return mergeState(state, {
        quoteToken: JSON.parse(
          JSON.stringify({
            ...(state.quoteToken as TokenWithApprovalContext),
            ...action.quoteToken,
          })
        ),
      })
    }
    case SET_CURRENT_TOKEN:
      return mergeState(state, { currentToken: action.currentToken })

    case UPDATE_CURRENT_TOKEN:
      return mergeState(state, {
        currentToken: JSON.parse(
          JSON.stringify({
            ...(state.currentToken as TokenWithApprovalContext),
            ...action.currentToken,
          })
        ),
      })

    case STORE_TOKENS:
      return mergeState(state, { currentToken: action.currentToken, quoteToken: action.quoteToken })

    case INIT_TOKENS:
      return mergeState(state, {
        currentToken: action.currentToken,
        quoteToken: action.quoteToken,
        tokenLoading: false,
      })

    case UPDATE_TOKENS:
      return mergeState(state, { tokens: action.tokens })

    case SET_TOKEN_LOADING:
      return { ...state, tokenLoading: action.status }

    case MERGE_TOKENS:
      return mergeState(state, {
        tokens: action.tokens,
      })

    case UPDATE_TOKEN_PRICES:
      return mergeState(state, {}, action.tokenPrices)

    case SET_FAVORITES:
      return { ...state, favorites: action.tokenIds }

    case START_ADD_FAVORITE_TOKEN:
      return {
        ...state,
        favorites: [...state.favorites, ...action.payload],
        isUpdatingFavorites: true,
      }
    case SUCCESS_ADD_FAVORITE_TOKEN:
    case SUCCESS_REMOVE_FAVORITE_TOKEN:
      return {
        ...state,
        isUpdatingFavorites: false,
      }
    case FAIL_ADD_FAVORITE_TOKEN:
      return {
        ...state,
        favorites: action.payload
          ? state.favorites.filter((tokenId) => tokenId !== action.payload)
          : state.favorites,
        isUpdatingFavorites: false,
      }
    case START_REMOVE_FAVORITE_TOKEN:
      return {
        ...state,
        favorites: state.favorites.filter((tokenId) => tokenId !== action.payload),
        isUpdatingFavorites: true,
      }
    case FAIL_REMOVE_FAVORITE_TOKEN:
      return {
        ...state,
        favorites: [...state.favorites, action.payload],
        isUpdatingFavorites: false,
      }
    case ADD_FAVORITE_TO_SYNC_QUEUE:
      return {
        ...state,
        syncFavoritesQueue: [
          ...state.syncFavoritesQueue,
          { action: 'add', tokenId: action.payload },
        ],
      }
    case REMOVE_FAVORITE_TO_SYNC_QUEUE:
      return {
        ...state,
        syncFavoritesQueue: [
          ...state.syncFavoritesQueue,
          { action: 'remove', tokenId: action.payload },
        ],
      }
    case START_SYNC_FAVORITES:
      return {
        ...state,
        isUpdatingFavorites: true,
        syncFavoritesQueue: [],
      }
    case SUCCESS_SYNC_FAVORITES:
      return {
        ...state,
        isUpdatingFavorites: false,
      }
    case FAIL_SYNC_FAVORITES:
      return {
        ...state,
        isUpdatingFavorites: false,
        syncFavoritesQueue: [...action.payload, ...state.syncFavoritesQueue],
      }
    default:
      return state
  }
}

export default tokensReducer

export const defaultTokensState: TokensState = {
  tokens: [],

  favorites: [],
  syncFavoritesQueue: [],
  isUpdatingFavorites: false,

  tokenLoading: false,
  // intentional, it's fetched before it becomes an issue
  currentToken: {} as TokenWithApprovalContext,
  quoteToken: {} as TokenWithApprovalContext,
}

export type TokensStateBeforeInit = {
  tokens: TokenWithApprovalContext[]
  favorites: string[]
  quoteToken: TokenWithApprovalContext
  currentToken: TokenWithApprovalContext
  tokenLoading: boolean
  wallet: TokenBalance[]
}

export type SyncFavoriteRecord = { action: 'add' | 'remove'; tokenId: string }

export type TokensState = {
  tokens: TokenWithApprovalContext[]
  favorites: string[]
  isUpdatingFavorites: boolean
  syncFavoritesQueue: Array<SyncFavoriteRecord>
  quoteToken?: TokenWithApprovalContext
  currentToken?: TokenWithApprovalContext
  tokenLoading: boolean
}
